exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-apps-app-list-route-js": () => import("./../../../src/pages/apps/{AppList.route}.js" /* webpackChunkName: "component---src-pages-apps-app-list-route-js" */),
  "component---src-pages-apps-index-js": () => import("./../../../src/pages/apps/index.js" /* webpackChunkName: "component---src-pages-apps-index-js" */),
  "component---src-pages-blog-category-wp-category-slug-js": () => import("./../../../src/pages/blog/category/{WpCategory.slug}.js" /* webpackChunkName: "component---src-pages-blog-category-wp-category-slug-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-wp-post-slug-js": () => import("./../../../src/pages/blog/{WpPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-wp-post-slug-js" */),
  "component---src-pages-career-volunteer-index-js": () => import("./../../../src/pages/career-volunteer/index.js" /* webpackChunkName: "component---src-pages-career-volunteer-index-js" */),
  "component---src-pages-career-volunteer-wp-job-post-slug-js": () => import("./../../../src/pages/career-volunteer/{WpJobPost.slug}.js" /* webpackChunkName: "component---src-pages-career-volunteer-wp-job-post-slug-js" */),
  "component---src-pages-collaborate-index-js": () => import("./../../../src/pages/collaborate/index.js" /* webpackChunkName: "component---src-pages-collaborate-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact/success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-donate-bd-js": () => import("./../../../src/pages/donate/bd.js" /* webpackChunkName: "component---src-pages-donate-bd-js" */),
  "component---src-pages-donate-index-js": () => import("./../../../src/pages/donate/index.js" /* webpackChunkName: "component---src-pages-donate-index-js" */),
  "component---src-pages-donate-success-js": () => import("./../../../src/pages/donate/success.js" /* webpackChunkName: "component---src-pages-donate-success-js" */),
  "component---src-pages-em-bkash-sadaqah-jsx": () => import("./../../../src/pages/em/bkash-sadaqah.jsx" /* webpackChunkName: "component---src-pages-em-bkash-sadaqah-jsx" */),
  "component---src-pages-em-slite-link-jsx": () => import("./../../../src/pages/em/[...slite_link].jsx" /* webpackChunkName: "component---src-pages-em-slite-link-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-prayer-time-js": () => import("./../../../src/pages/prayer-time.js" /* webpackChunkName: "component---src-pages-prayer-time-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-ramadan-impact-index-js": () => import("./../../../src/pages/ramadan-impact/index.js" /* webpackChunkName: "component---src-pages-ramadan-impact-index-js" */),
  "component---src-pages-support-faq-category-category-js": () => import("./../../../src/pages/support/{FaqCategory.category}.js" /* webpackChunkName: "component---src-pages-support-faq-category-category-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-support-wp-faq-slug-js": () => import("./../../../src/pages/support/{WpFaq.slug}.js" /* webpackChunkName: "component---src-pages-support-wp-faq-slug-js" */)
}

